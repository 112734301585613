import {RedError} from "../lib/styled-components/RedError";
import {SplitScreen} from "../lib/johncornish/components/v1/screen/SplitScreen";
import {TailwindTwoColumnGrid} from "../lib/styled-components/TailwindTwoColumnGrid";
import {DefaultPageLayout} from "../lib/layouts/DefaultPageLayout";
import {useState} from "react";
import {Language, ServiceArea} from "../lib/object-control/language-or-service-area";
import {GreenCheckmark} from "../lib/styled-components/GreenCheckmark";
import {InlineFormRow} from "../lib/johncornish/components/v1/form/InlineFormRow";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {BasicObjectUpdatingForm} from "../lib/johncornish/components/v1/form/BasicObjectUpdatingForm";
import {APIUserListPage} from "../lib/johncornish/components/v1/list/APIUserListPage";
import {useCurrentUser} from "../features/auth/useUserData";
import {DeleteWithConfirmation} from "../lib/styled-components/DeleteWithConfirmation";
import {toTestId} from "../lib/utils";
import {useApi} from "../store";
import {useAllow} from "../lib/auth/useAllow";

export function SiteSettingsPage() {
  const {user} = useCurrentUser();
  useAllow(user, 'Admin');

  const {tryulaApi} = useApi();

  const [newServiceArea, setNewServiceArea] = useState({name: ''});
  const [newLanguage, setNewLanguage] = useState({name: ''});

  const {
    data: serviceAreas,
    error: serviceAreasError,
    isLoading: areServiceAreasLoading,
  } = tryulaApi.useGetAllServiceAreasQuery();

  const {
    data: languages,
    error: languagesError,
    isLoading: areLanguagesLoading,
  } = tryulaApi.useGetAllLanguagesQuery();

  if (serviceAreasError)
    return <RedError>{JSON.stringify(serviceAreasError)}</RedError>;
  if (languagesError)
    return <RedError>{JSON.stringify(languagesError)}</RedError>;
  if (areServiceAreasLoading || areLanguagesLoading)
    return <p>Loading available service areas and languages...</p>;
  if (!serviceAreas)
    return <RedError>Unknown error loading service areas</RedError>;
  if (!languages)
    return <RedError>Unknown error loading languages</RedError>;

  const serviceAreaOrLanguageInputClassName = "rounded border border-stroke p-2 font-normal text-black focus:border-primary focus-visible:outline-none";
  return <>
    <PageTitle title="Site Settings"/>
    <SplitScreen screenComponent={TailwindTwoColumnGrid}>
      <div>
        <BasicObjectUpdatingForm
          resourceName="serviceArea"
          saveText="Create Service Area"
          saveClassName={"ml-1 mt-1 bg-[#27AAE1] hover:bg-blue-500 text-white font-bold px-4 py-1 rounded"}
          state={newServiceArea}
          setState={setNewServiceArea}
          fieldsWithLabels={[
            ['', 'name',
              {
                placeholder: 'Service Area',
                className: serviceAreaOrLanguageInputClassName
              }
            ]]}
          useMutator={tryulaApi.useCreateServiceAreaMutation}
          validate={[
            ['name', state => !!state.name, 'Service area must not be empty!']
          ]}
          rowElement={InlineFormRow}
          errorElement={RedError}
          updateSuccessElement={GreenCheckmark}/>
        <br/>
        <APIUserListPage
          items={serviceAreas}
          error={serviceAreasError}
          isLoading={areServiceAreasLoading}
          resourcePlural="service areas"
          resourceSingular="serviceArea"
          pageLayoutComponent={DefaultPageLayout}
          itemComponent={ServiceAreaElement}
          emptyResultComponent={() => <p>No service areas configured.</p>}/>
      </div>
      <div>
        <BasicObjectUpdatingForm
          resourceName="language"
          saveText="Create Language"
          saveClassName={"ml-1 mt-1 bg-[#27AAE1] hover:bg-blue-500 text-white font-bold px-4 py-1 rounded"}
          state={newLanguage}
          setState={setNewLanguage}
          fieldsWithLabels={[
            ['', 'name',
              {
                placeholder: 'Language',
                className: serviceAreaOrLanguageInputClassName
              }
            ]]}
          useMutator={tryulaApi.useCreateLanguageMutation}
          validate={[
            ['name', state => !!state.name, 'Language must not be empty!']
          ]}
          rowElement={InlineFormRow}
          errorElement={RedError}
          updateSuccessElement={GreenCheckmark}/>
        <br/>
        <APIUserListPage
          items={languages}
          error={languagesError}
          isLoading={areLanguagesLoading}
          resourcePlural="languages"
          resourceSingular="language"
          pageLayoutComponent={DefaultPageLayout}
          itemComponent={LanguageElement}
          emptyResultComponent={() => <p>No languages configured.</p>}/>
      </div>
    </SplitScreen>
  </>;
}

const ServiceAreaElement = ({serviceArea}: { serviceArea: ServiceArea }) => {
  const {tryulaApi} = useApi();

  const [deleteServiceArea] = tryulaApi.useDeleteServiceAreaMutation();
  const [deleteError, setDeleteError] = useState('');

  return <div data-testid="service-area">
    <span data-testid="service-area-name">
      {serviceArea.name}
    </span>

    <DeleteWithConfirmation
      dataObject={serviceArea}
      deleteMutator={deleteServiceArea}
      setDeleteError={setDeleteError}
      readableName={serviceArea.name}
      uniqueIdentifier={toTestId(...serviceArea.name.split(' '))}/>

    {!!deleteError && <RedError>{deleteError}</RedError>}
  </div>
};

const LanguageElement = ({language}: { language: Language }) => {
  const {tryulaApi} = useApi();

  const [deleteLanguage] = tryulaApi.useDeleteLanguageMutation();
  const [deleteError, setDeleteError] = useState('');

  return <div data-testid="language">
    <span data-testid="language-name">
      {language.name}
    </span>

    <DeleteWithConfirmation
      dataObject={language}
      deleteMutator={deleteLanguage}
      setDeleteError={setDeleteError}
      readableName={language.name}
      uniqueIdentifier={toTestId(...language.name.split(' '))}/>

    {!!deleteError && <RedError>{deleteError}</RedError>}
  </div>;
};