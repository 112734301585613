import {useGetAllLoginFailuresQuery} from "../services/tryulaApi";
import {RedError} from "../lib/styled-components/RedError";

export function LoginFailuresForSuperAdmin() {
  const {
    data: loginFailures,
    error: loginFailuresFetchError,
    isLoading: isLoginFailuresLoading
  } = useGetAllLoginFailuresQuery();

  if (!!loginFailuresFetchError) {
    return <RedError>Error returning login failures</RedError>;
  }

  if (isLoginFailuresLoading) {
    return <>Loading login failures...</>;
  }

  return <div className="col-span-3">
    <h3 className="text-lg font-semibold mt-3 mb-3">Most recent login failures</h3>
    <ul className="space-y-3">
      {!!loginFailures
        ? <>{loginFailures.map((lf, i) =>
          <li key={i} className="grid grid-cols-5">
            <p className="col-span-2 break-words">{lf.email}</p>
            <p className="col-span-1 text-center">at</p>
            <p className="col-span-2 text-end">{lf.created_at}</p>
          </li>)}</>
        : <li>No login failures</li>}
    </ul>
  </div>;
}