import {toTestId} from "../../../../utils";
import {JSX} from "react";
import {BasicLink} from "./BasicLink";
import {chopLongGreetings} from "./utils";

interface TopNavProps {
  leftLinks: [string, string][],
  rightLinks: [string, string][],
  greeting?: string,
  linkComponent?: ({children, ...props}: any) => JSX.Element,
  logoElement?: JSX.Element,
  homepageSectionComponent?: (({children}: any) => JSX.Element) | undefined
}

export const TopNav = (
    {
      leftLinks,
      rightLinks,
      greeting,
      linkComponent: LinkComponent = BasicLink,
      logoElement,
      homepageSectionComponent: HomepageSectionComponent
    }:
      TopNavProps) => {
  if (!!HomepageSectionComponent) {
      return <HomepageSectionComponent>
        {chopLongGreetings(greeting, 20)}

        {!!logoElement && logoElement}

        {leftLinks.map(([text, href], i) =>
          <LinkComponent
            key={i}
            data-testid={`top-left-nav-${toTestId(...text.split(' '))}`}
            href={href}>
            {text}
          </LinkComponent>)}

        {rightLinks.map(([text, href], i) =>
          <LinkComponent
            key={i}
            data-testid={`top-right-nav-${toTestId(...text.split(' '))}`}
            href={href}>
            {text}
          </LinkComponent>)}
      </HomepageSectionComponent>
    }

    return <div className="max-w-[1048px] w-full grid grid-cols-4 px-4 xl:px-14">
      <div className="col-span-2 flex items-center gap-x-2">
        {!!logoElement && logoElement}

        {leftLinks.map(([text, href]) =>
          <LinkComponent
            key={toTestId(...text.split(' '))}
            data-testid={`top-left-nav-${toTestId(...text.split(' '))}`}
            href={href}>
            {text}
          </LinkComponent>)}
      </div>

      <div className="flex justify-end col-span-2 items-center">
        {rightLinks.map(([text, href], i) =>
          <LinkComponent
            key={i}
            data-testid={`top-right-nav-${toTestId(...text.split(' '))}`}
            href={href}>
            {text}
          </LinkComponent>)}
      </div>
      <div className="flex col-span-4 justify-end">
        {greeting && <span
          className="px-1"
          data-testid="top-nav-greeting"
        >
          {chopLongGreetings(greeting, 30)}
        </span>}
      </div>
    </div>;
  }
;
