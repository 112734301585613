import {Agent, AgentFilter} from "../../lib/object-control/agent";
import {AgentCard} from "./AgentCard";
import {CheckboxState} from "../../lib/johncornish/components/v1/filter/types";

import {excludeBySelection, excludeByString} from "../../lib/johncornish/components/v1/filter/utils";
import {List} from "../../lib/johncornish/components/v1/list/List";
import {useSearchParams} from "react-router-dom";
import {Key, useState} from "react";
import {useApi} from "../../store";

interface AgentsProps {
  filter: AgentFilter;
}

export function Agents({filter}: AgentsProps) {
  const {tryulaApi} = useApi();

  const [searchParams] = useSearchParams();

  const [seed] =
    useState(searchParams.get('seed') || `${Math.floor(Math.random() * 1_000)}`);

  const {
    data: agents,
    error,
    isLoading,
  } = tryulaApi.useGetRandomizedAgentsQuery(seed);

  if (error) return <>
    Application error:
    {'status' in error && error.status}
    {'data' in error && JSON.stringify(error.data)}
  </>;

  if (isLoading) return <>Loading agents...</>;

  if (!agents) return <>Received empty response from agents API</>;

  if (!!filter) {
    return <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
      {
        agents
          .filter(agentFilterMatcher({
            agentNameFilter: (filter?.name || '') as string,
            selectedServiceAreas: (filter?.service_areas || {}) as CheckboxState,
            selectedLanguages: (filter?.languages || {}) as CheckboxState
          }))
          .map((agent: Agent, i: Key | null | undefined) =>
            <AgentCard key={i} agent={agent} seed={seed} filter={filter}/>)
      }
    </div>;
  }

  return <List items={agents} itemComponent={AgentCard} resourceName="agent"/>
}

type AgentFilterMatcherOptions = {
  agentNameFilter: string,
  selectedServiceAreas: CheckboxState,
  selectedLanguages: CheckboxState
};

const agentFilterMatcher =
  ({
     agentNameFilter,
     selectedServiceAreas,
     selectedLanguages,
   }: AgentFilterMatcherOptions) =>
    (agent: Agent) => {
      if (excludeByString(`${agent.first_name} ${agent.last_name}`, agentNameFilter)) {
        return false;
      }

      if (excludeBySelection(agent.service_areas, selectedServiceAreas)) {
        return false;
      }

      if (excludeBySelection(agent.languages, selectedLanguages)) {
        return false;
      }

      return true
    };
