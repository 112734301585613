import {seededAgentSearchParams, toTestId} from "../../lib/utils";
import {Agent, AgentFilter} from "../../lib/object-control/agent";
import {Link} from "react-router-dom";
import {FC} from "react";

type Props = { agent: Agent, seed: string, filter: AgentFilter };
export const AgentCard: FC<Props> = ({agent, seed, filter}) => {
  const borderColor =
    agent.published ? 'border-[#B1B1B1]' : 'border-[#27AAE1]';

  const agentNameTestId = toTestId(agent.first_name, agent.last_name);
  return <div
    className={`border-[1px] border-solid rounded-[15px] p-[15px] gap-[20px] flex flex-col justify-between ${borderColor}`}
    data-testid="AgentCard">
    <div className="valign">
      <div className="flex flex-col gap-4">
        {!!agent.avatar_url && <img
          data-testid={`agent-img-${agentNameTestId}`}
          src={agent.avatar_url}
          alt=""
          className="rounded-full mx-auto w-full h-auto aspect-square object-cover mb-1"
          // className="rounded-full mx-auto w-[96px] h-[96px] aspect-square object-cover mb-1"
        />}

        <h3 className="agentName text-center text-[14px] overflow-x-clip">
          {agent.first_name} {agent.last_name}
        </h3>

        <div className="grid grid-cols-7 text-xs space-x-1 text-center">
          <div className="col-span-3 text-right">{agent.brokerage_office_name}</div>
          <div className="col-span-1 text-center">|</div>
          <div className="col-span-3 text-left">Lic # {agent.agent_license}</div>
        </div>
      </div>
    </div>
    <div className="valign flex flex-col gap-4">
      <Link
        className="bg-[#A9D046] text-white rounded-[100px] p-[12px] px-[25px] block uppercase text-[14px] text-center"
        data-testid={`view-agent-${agentNameTestId}`}
        to={`/agent/${agent.id}${seededAgentSearchParams(seed, filter)}`}>
        View Agent
      </Link>
    </div>
  </div>;
};

