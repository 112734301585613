import {TwoElements} from "../johncornish/components/v1/form/types";
import {FC} from "react";


export const TransactionColumn = ({children}: { children: any }) =>
  <div
    data-testid="transaction-column"
    className="col-span-1">
    {children}
  </div>;

interface TransactionRowProps {
  children: TwoElements,
  inputId?: string
}

export const TransactionRow: FC<TransactionRowProps> = ({children, inputId}) => {
  const [label, field] = children;

  let maybeHtmlFor = {};
  if (!!inputId) {
    maybeHtmlFor = {htmlFor: inputId};
  }

  return <div className="mb-2 grid grid-cols-1">
    <label className="font-semibold text-[#27aae1]" {...maybeHtmlFor}>
      {label}
    </label>

    <div className="flex space-x-8">
      {field}
    </div>
  </div>;
};