import {useCurrentUser} from "../../features/auth/useUserData";
import {
  checkboxSelectionFromQueryString,
  loginHref,
  logoutHrefWithOverrideUrl,
  seededAgentSearchParams
} from "../utils";
import {BlueLinkAnchor} from "../styled-components/BlueLinkAnchor";
import {useSearchParams} from "react-router-dom";
import {ContentHome, FullLogoSrc, USmallLogoSrc} from "../../config/siteSettings";
import {TopNav} from "../johncornish/components/v1/nav/TopNav";
import {AgentFilter} from "../object-control/agent";
import {WhiteHomepageLinkAnchor} from "../styled-components/WhiteHomepageLinkAnchor";

const HomepageHeaderWithImage = ({children}: { children: any[] }) => {
  const [greeting, ...links] = children;

  return <>
    <div
      className="w-full px-5 pt-9 pb-10 bg-[url('https://app.tryula.com/Hero-Homepage-cropped.jpg')] bg-cover flex flex-col items-center text-white">
      <div className="max-w-fit block">
        <a href={ContentHome} aria-current="page" data-testid="logo-back-to-tryula.com">
          <img width="407" height="101"
               src={FullLogoSrc}
               className="css-filter size-full" alt="Logo - Tryula Hero" decoding="async" data-type="string"
               sizes="(max-width: 407px) 100vw, 407px"/>
        </a>
      </div>

      <div className={`pt-3 ${!!greeting ? 'pb-3' : 'pb-9'} text-xl`} style={{'textShadow': '0 4px 10px rgba(0,0,0,0.6)'}}>
        <div className="w-full">
          <div className="grid grid-cols-1 md:flex md:items-center gap-x-8 gap-y-2">
          {/*<div className="flex items-center gap-x-2">*/}
            {links}
          </div>
          <div data-testid="top-nav-greeting" className="text-center pt-4">
            {greeting}
          </div>
        </div>
      </div>
    </div>
  </>;
};

export const UserTopNav = () => {
  const {user} = useCurrentUser()
  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('return_url');

  const filter: AgentFilter = {
    name: searchParams.get('name') || '',
    service_areas: checkboxSelectionFromQueryString(searchParams.get('service_areas')),
    languages: checkboxSelectionFromQueryString(searchParams.get('languages'))
  }
  const searchWithSeedAndFilter =
    `/${seededAgentSearchParams(searchParams.get('seed'), filter)}`;

  const agentProfileMatcher = /^(\/agent\/\d+)$/;

  const contentLinks: [string, string][] = [
    ['Benefits', `${ContentHome}/benefits-of-tryula-com/`],
    ['FAQs', `${ContentHome}/#faqs`],
  ];

  const contentLinksOrBreadCrumbs: [string, string][] =
    ['/'].includes(window.location.pathname)
      ? contentLinks
      : [['Back to Tryula.com', ContentHome]];

  const breadCrumbs: [string, string][] =
    !!window.location.pathname.match(agentProfileMatcher)
      ? [['Back to search', searchWithSeedAndFilter]]
      : contentLinksOrBreadCrumbs;

  const linkComponent =
    ['/'].includes(window.location.pathname)
      ? WhiteHomepageLinkAnchor
      : BlueLinkAnchor;

  const logoElement =
    ['/'].includes(window.location.pathname)
      ? undefined
      : TryulaLogo;

  const homepageSectionComponent =
    ['/'].includes(window.location.pathname)
      ? HomepageHeaderWithImage
      : undefined;

  if (!user) {
    const loginLinks: [string, string][] =
      ['/login', '/sign-up'].includes(window.location.pathname)
        ? []
        : [
          ['Login', loginHref(returnUrl)],
        ];

    return <TopNav
      homepageSectionComponent={homepageSectionComponent}
      logoElement={logoElement}
      linkComponent={linkComponent}
      leftLinks={breadCrumbs}
      rightLinks={loginLinks}
    />;
  }

  if (user.user_type === 'Customer') {
    const dashboardLinks: [string, string][] =
      ['/dashboard'].includes(window.location.pathname)
        ? []
        : [['Dashboard', '/dashboard']];

    return <TopNav
      homepageSectionComponent={homepageSectionComponent}
      logoElement={logoElement}
      linkComponent={linkComponent}
      leftLinks={breadCrumbs}
      greeting={user.email}
      rightLinks={[
        ...dashboardLinks,
        ['Logout', logoutHrefWithOverrideUrl()],
      ]}
    />;
  }

  if (user.user_type === 'Agent') {
    return <TopNav
      homepageSectionComponent={homepageSectionComponent}
      logoElement={logoElement}
      linkComponent={linkComponent}
      leftLinks={['/'].includes(window.location.pathname)
        ? contentLinks : []}
      greeting={`${user.first_name} ${user.last_name}`}
      rightLinks={[
        ['Logout', logoutHrefWithOverrideUrl()],
      ]}
    />;
  }

  // Admin
  return <TopNav
    homepageSectionComponent={homepageSectionComponent}
    logoElement={logoElement}
    linkComponent={linkComponent}
    leftLinks={['/'].includes(window.location.pathname)
      ? contentLinks : []}
    greeting={user.email}
    rightLinks={[
      ['Logout', logoutHrefWithOverrideUrl()],
    ]}
  />;
};

const TryulaLogo = <a href={ContentHome}>
  <img className="max-w-8" src={USmallLogoSrc} alt=""/>
</a>;
