import {useCurrentUser} from "../features/auth/useUserData";
import {nullSafeFileName, nullSafeFullURL} from "../lib/johncornish/utils/v1/urls";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {useAllow} from "../lib/auth/useAllow";

export function AgentReferralAgreementPage() {
  const {user} = useCurrentUser();
  useAllow(user, 'Agent');

  if (!user?.agent) {
    return null
  }

  const {agent} = user;

  return <div className="mt-5">
    <PageTitle title="Referral Agreement"/>
    <h3>
      <a
        target="_blank"
        rel="noreferrer"
        className="text-[#27AAE1] hover:underline"
        data-testid="agent-referral-agreement-link"
        href={nullSafeFullURL(agent.referral_agreement_url, '#')}>
        {nullSafeFileName(agent.referral_agreement_url, 'no referral agreement found')}
      </a>
    </h3>
  </div>;
}
