import {TwoChildren} from "./types";

export const BasicFormRow = ({children}: TwoChildren) => {
  const [label, field] = children;
  return <>
    <label>{label}:
      <br/>
      {field}
      <br/>
    </label>
  </>
};