import {User} from "../lib/object-control/user";

export const ContentHome = process.env.REACT_APP_CONTENT_HOME || 'https://tryulaemdev.wpengine.com';
export const appUrl = process.env.REACT_APP_APP_URL ?? 'https://app.tryula.com';
export const USmallLogoSrc = `${appUrl}/favico.png`;
export const FullLogoSrc = `${appUrl}/Logo-Tryula-Hero-with-TM.png`;

export const enableCreateTransactionsForSuperAdmin = process.env.REACT_APP_ENABLE_SUPER_ADMIN_CREATE_TRANSACTIONS !== undefined;

export const FullPrivacyPolicyText =
  <>
Tryula, Inc., a Colorado corporation d/b/a Tryula Realty and its affiliates (collectively “Tryula”, the “Company”, “us”, “our” or “we”) welcome you to our website. This Privacy Policy (the “Policy”) applies to this site and all other sites operated or sponsored by the Company (collectively, the “Website”). The purpose of this Policy is to describe what Personal Information (as defined below) and other information we may collect from you through the Website, how we may collect it, with whom we may share it, as well as the choices you have regarding our collection of information and our use and disclosure of that information to other parties.

<br/><br/>

Please review the Policy carefully. By accessing the Website, you are indicating your agreement to the Policy and your use of our Website constitutes your acceptance of this Policy and your consent to the practices it describes. If you do not agree to the terms of this Policy, please do not use our Website. The Company reserves the right to modify the Policy at any time, without notice. Such changes will be incorporated into this document and posted on the Website and will become effective as soon as they are posted on the Website. The current version of the Policy supersedes any prior versions.

<br/><br/>

If you have any questions about the Policy, you may contact us by writing us at contact@tryula.com.

<br/><br/>

The Policy does not apply to any information received by the Company from you or from any other sources on your behalf outside of the Website, including in the course of any client relationship that you may enter into with the Company. Should you ultimately formally enter into a client relationship with a licensed real estate broker through the Website, the Company’s obligations with respect to information that it receives from you during the course of that relationship, if any, will be governed by other applicable law.

<br/><br/>

This Policy does not apply to information collected by third parties, such as when you visit a third-party website, or interact with online advertisements. Please take a moment to review the privacy policies of third-party sites before you disclose your Personal Information to them, as we disclaim responsibility for their actions with regard to your information.

<br/><br/>

Our Website is not intended for children under 18 years of age. No one under age 18 may provide any information to or on the Website. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on this Website or through any of its features, or provide any information about yourself to us, including your name, address, telephone number, and/or email address. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at the address or number listed above.

<br/><br/>

Information collected and how it is used:

<br/><br/>

We may collect personal and other information about you when you use our Website, as described below. “Personal Information” is information about an identifiable individual, such as their name, mailing address, email address, or phone number, whether used alone, or when combined with other personal or identifying information which is linked or linkable to a specific individual. We typically collect Personal Information and other data from you and use that information as set forth in this Policy or otherwise communicated to you from time to time (subject to your consent when required by applicable laws). The Company may collect Personal Information you choose to provide while on the Website (such as Personal Information entered into the Website when you request to receive more information about real estate transactions and potentially working with a licensed real estate broker on our Website.)

<br/><br/>

Generally speaking, our Website is informational. Any Personal Information which would permit someone to contact you is only requested in order for us to connect with you a licensed real estate broker via our Website. We do not collect any information which would permit someone to contact you unless you voluntarily provide it through the Website’s contact features, and you request that your Personal Information be shared in order to connect with you a licensed real estate broker.

<br/><br/>

The Website also collects certain information automatically when you access or use the Website. For example, Tryula may monitor and compile statistical information concerning the usage of the Website. This information allows the Company to monitor its utilization and continuously improve its quality. Examples of this information would include, but not be limited to, the number of visitors to the Website, or to sections or pages within the Website, patterns of traffic flowing through the Website, length of time spent on the Website, or in sections or pages of the Website, the other sites that refer visitors to the Website, the pages of the Website that visitors frequently use as entry and exit points, utilization of the browser and operating systems and versions used by visitors to the Website, etc.

<br/><br/>

In order to compile the above information, Tryula may collect and store information regarding your “ISP” (Internet Service Provider), your “IP” (Internet Protocol) address, your operating system version, your browser version, the pages you visit within the Website, the length of time you spend on pages within the Website, the site from which you linked to ours, search terms you used in search engines which resulted in you linking to the Website, etc. While all this information can be associated with the IP address your computer had while you visited the Website, it will not be associated with you as an individual, or associated with any other information you may submit through the Website, or that Tryula may store about you for any other purposes. Tryula will not sell or share the above information with a third party, except as set forth below, and the information gathered is only utilized internally to improve the quality of the Website.

<br/><br/>

The Website may also use “cookie” features to enhance the experience of users of the Website. Computer “cookies” (also known as an HTTP cookie or a browser cookie) are small files which are stored on a user’s web browser that contain a small amount of data specific to a particular client and website, and can be accessed either by the web server or the client computer. Most web browsers have options which allow the user to see what cookies, if any, have been stored on the computer, and to optionally enable or delete cookies.

<br/><br/>

We do not sell your Personal Information to any third parties for any reason. In the event that we go through a business transition, such as a merger, acquisition, liquidation or sale of all or a portion of our assets, Personal Information will, in most instances, be part of the assets transferred, or may be disclosed (subject to confidentiality restrictions) during the due diligence process. In addition, we may, without your consent, access and disclose your Personal Information, any communications sent or received by you, and any other information that we may have about you as may be permitted or as we believe in good faith may be reasonably necessary to comply with any law, regulation, rule or court order; pursuant to subpoenas or other lawful requests from governmental, regulatory or administrative agencies or law enforcement authorities (including in response to law enforcement); to respond to any claims, or to prevent, investigate, identify persons or organizations potentially involved in, or take any action regarding suspected fraud, violations of our Terms of Service, or activity that appears to us to be illegal or we believe may expose us to legal liability. Additionally, we may disclose Personal Information in situations that we believe to be emergencies involving potential threats to the physical safety of any person or property, if we believe that your information in any way relates to that threat.

<br/><br/>

Your Choices:

<br/><br/>

It is possible for you to use most of our Website without giving us any Personal Information. If you would like to update your Personal Information or have questions about accessing or removing any Personal Information you have provided, you can contact us by writing us at contact@tryula.com.

<br/><br/>

TERMS OF USE AND DISCLAIMER

<br/><br/>

Tryula, Inc., a Colorado corporation d/b/a Tryula Realty and its affiliates (collectively “Tryula” or the “Company”) welcome you to our website. This Terms of Use and Disclaimer (the “Terms of Use”) applies to this site and all other sites operated or sponsored by the Company (collectively, the “Website”). These Terms of Use do not apply to your use of unaffiliated websites of any other companies, organizations or individuals to which this Website merely links.

<br/><br/>

Please review the Terms of Use carefully. By accessing the Website, you are indicating your agreement to the Terms of Use and your use of our Website constitutes your acceptance of these Terms of Use. The Company reserves the right to modify the Terms of Use at any time, without notice. Such changes will be incorporated into this document and posted on the Website and will become effective as soon as they are posted on the Website. The current version of the Terms of Use supersedes any prior versions. You should check these Terms of Use periodically for any changes. By using this Website after we post any changes to these Terms of Use you agree to accept those changes, whether or not you have reviewed them. If you choose not to accept these Terms of Use at any time, please do not use this Website.

<br/><br/>

If you have any questions about the Terms of Use, you may contact us by writing us at contact@tryula.com.

<br/><br/>

You expressly understand and agree that this Website and the information available through this Website are provided on an “as is” basis and are for general information and reference purposes only. The contents of the Website do not constitute advice and should not be relied upon in making or refraining from making, any decision regarding the sale or purchase of real estate property or any other matter. The information here cannot replace or substitute for the professional advice of a licensed real estate broker to answer any questions and help you understand and use the information available through this Website.

<br/><br/>

There is no guarantee that the information on the Website is always correct, complete, or up-to-date. Tryula makes no warranty as to the accuracy, completeness, authenticity, or reliability of any information available through the Website. All those that review the Website should take the appropriate precautions to verify all information. Your use of any information or materials on this Website is entirely at your own risk, for which the Company shall not be liable. It shall be your own responsibility to ensure that any services or information available through this Website meet your specific requirements. Tryula expressly disclaims any and all warranties with respect to the Website and the information available through the Website, including but not limited to the implied warranties of merchantability and fitness for a particular purpose. To the maximum extent permitted by law, the Company will not be liable for any indirect or consequential loss or damage (including without limitation loss of business, opportunity, data, profits) arising out of or in connection with the use of the Website. The Company makes no warranty that the functionality of the Website will be uninterrupted or error free, that defects will be corrected or that the Website or the server that makes it available are free of viruses or anything else which may be harmful or destructive.

<br/><br/>

This Website contains intellectual property, including but not limited to trademarks and copyrighted material, which is owned by or licensed to the Company. No reproduction, distribution, or transmission of the intellectual property materials on the Website is permitted without the express written permission of the Company.

<br/><br/>

Articles and pages on this Website may contain links to other resources on the Internet. Those links are provided as aids to help you identify and locate other Internet resources that may be of interest, and are not intended to state or imply that the Company sponsors, is affiliated or associated with, or is legally authorized to use any trade name, registered trademark, logo, legal or official seal, or copyrighted symbol that may be reflected in the links. The Company does not sponsor, and is not responsible for the content or functionality of any website published outside of our domain, including resources reached through links from or to us, and the Company is not responsible or liable for any errors, omissions, or damages of any kind resulting from access to other Internet resources.

<br/><br/>

You agree to indemnify and hold the Company and its owners, officers, managers, members, shareholders, employees and agents harmless from and against all liabilities, legal fees, damages, losses, costs and other expenses in relation to any claims or actions brought against the Company arising out of any breach by you of these Terms of Use or other liabilities arising out of your use of this Website.

<br/><br/>

Agents listed on Tryula.com agree to receive text communications from Tryula via Twilio messaging service.

<br/><br/>

<strong>
  We believe in your right to choose the best real estate professional for your needs. As a user of this website,
  you have the complete freedom to select any broker listed on our platform. The choice of which broker to work with
  is entirely yours.

  <br/><br/>

  We perform a vetting process based on the number of annual sales, internet reviews and direct personal interviews
  to ensure that the agents listed on our site meet certain standards of quality and professionalism. However, we
  make no representations or warranties regarding the specific qualifications, expertise, or experience of any
  individual agent. We encourage you to conduct your own due diligence to ensure the agent you select meets your
  expectations.

  <br/><br/>

  Please note that the brokers listed on this site are independent professionals. They are not employees,
  independent contractors, brokers, or otherwise affiliated with Tryula, Inc. d/b/a Tryula Realty (“Tryula”).
  Brokers do not hold their real estate licenses through Tryula. Any agreements or contracts entered into with a
  broker or brokerage firm selected through our site are solely between you and the broker or brokerage firm you
  select. Tryula is not a party to these agreements and bears no responsibility for any issues, disputes, or
  outcomes that may arise from your interactions with the Broker you select.
</strong>
</>;

export const AnyAdminLinks: [string, string][] = [
  ['Dashboard', '/dashboard'],
  ['Transactions', '/transactions'],
  ['Customers', '/customers'],
  ['Agents', '/agents'],
  ['Create Agent', '/create-agent'],
];

export const SuperAdminLinks: [string, string][] =
  enableCreateTransactionsForSuperAdmin
    ? [
      ['Dashboard', '/dashboard'],
      ['Transactions', '/transactions'],
      ['Customers', '/customers'],
      ['Agents', '/agents'],
      ['Create Agent', '/create-agent'],
      ['Create Transaction', '/create-transaction'],
      ['Tryula Admins', '/tryula-admins'],
      ['Super Admins', '/super-admins'],
      ['Site Settings', '/site-settings'],
    ]
    : [
      ['Dashboard', '/dashboard'],
      ['Transactions', '/transactions'],
      ['Customers', '/customers'],
      ['Agents', '/agents'],
      ['Create Agent', '/create-agent'],
      ['Tryula Admins', '/tryula-admins'],
      ['Super Admins', '/super-admins'],
      ['Site Settings', '/site-settings'],
    ];
export const agentLinks =
  (user: User): [string, string][] =>
    [
      ['Transactions', '/transactions'],
      ['Archived Transactions', '/archived-transactions'],
      ['Edit Profile', `/agent/${user.agent?.id || 0}`],
      ['Referral Agreement', '/referral-agreement'],
      ['Privacy Policy', `${ContentHome}/privacy-policy-and-terms-of-use/`],
    ];
export const RedactionExplanationMessage = "Agent contact information will become available once a Rebate Guarantee is signed through the Engage Agent process.";
export const PostEngagementDirections = "This popup can now be closed and the agent's contact information will be visible when all signers are done or by refreshing their profile page.";
