import {useCurrentUser} from "../features/auth/useUserData";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {AdminAgentsList} from "./AdminAgentsList";
import {useAllow} from "../lib/auth/useAllow";

export function AgentsPage() {
  const {user} = useCurrentUser();
  useAllow(user, 'Admin', 'TryulaAdmin');

  return <>
    <PageTitle title="Agents"/>
    <AdminAgentsList userType={user?.user_type}/>
  </>;
}