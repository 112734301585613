import {useCurrentUser} from "../features/auth/useUserData";
import {CustomerDashboard} from "./CustomerDashboard";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {AdminStats} from "./AdminStats";
import {useAllow} from "../lib/auth/useAllow";
import {Navigate} from "react-router-dom";

export function CustomerOrAdminDashboardPage() {
  const {user} = useCurrentUser();
  useAllow(user, 'Admin', 'TryulaAdmin', 'Agent', 'Customer');

  if (user?.user_type === 'Agent') {
    return <Navigate to={'/transactions'}/>;
  }

  if (user?.user_type === 'Admin' || user?.user_type === 'TryulaAdmin') {
    return <>
      <PageTitle title="Dashboard"/>
      <AdminStats/>
    </>;
  }

  return <>
    <PageTitle title="Dashboard"/>
    <CustomerDashboard/>
  </>;
}

