import {MutationTrigger} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {FC, useState} from "react";
import {RedButton} from "./RedButton";
import {deleteOrDeactivateHandlerFor} from "../johncornish/utils/v1/deleteOrDeactivateHandlerFor";
import {BlueLinkButton} from "./BlueLinkButton";
import {BlueButton} from "./BlueButton";

type Props = {
  dataObject: { id: number },
  deleteMutator: MutationTrigger<any>,
  setDeleteError: (value: (((prevState: string) => string) | string)) => void,
  uniqueIdentifier: string,
  readableName?: string
};
export const DeleteWithConfirmation: FC<Props> = (
  {
    dataObject,
    setDeleteError,
    deleteMutator,
    uniqueIdentifier,
    readableName
  }) => {
  const [requestingDelete, setRequestingDelete] =
    useState(false);

  if (requestingDelete) {
    return <div className="flex flex-row items-center space-x-1">
      <p>Are you sure?</p>
      <RedButton
        data-testId={`yes-${uniqueIdentifier}`}
        onClick={deleteOrDeactivateHandlerFor(dataObject, deleteMutator, setDeleteError, true)}>
        Yes
      </RedButton>
      <BlueButton
        data-testId={`no-${uniqueIdentifier}`}
        onClick={() => setRequestingDelete(false)}>
        No
      </BlueButton>
    </div>;
  }

  return <BlueLinkButton
    data-testid={`delete-${uniqueIdentifier}`}
    onClick={() => setRequestingDelete(true)}
    aria-label={`Delete ${readableName ?? uniqueIdentifier}`}
  >
    Delete
  </BlueLinkButton>;
};