import {useCurrentUser} from "../features/auth/useUserData";
import {CreateOrEditAgent} from "../lib/agent/CreateOrEditAgent";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {useAllow} from "../lib/auth/useAllow";

export function CreateAgentPage() {
  const {user} = useCurrentUser();
  useAllow(user, 'Admin', 'TryulaAdmin');

  return <>
    <PageTitle title="New Agent"/>
    <CreateOrEditAgent agent={null}/>
  </>;
}
