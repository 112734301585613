import {
  Agent,
  AgentCreatingState,
  AgentFieldStyle,
  CreateOrEditAgentFields,
  InitialCreateAgentState,
  validPhoneNumber
} from "../object-control/agent";
import {useEffect, useState} from "react";
import {useUpdateAgentMutation} from "../../services/tryulaApi";
import {RedError} from "../styled-components/RedError";
import {GreenCheckmark} from "../styled-components/GreenCheckmark";
import {ProfileFieldRow} from "../styled-components/ProfileFieldRow";
import {BasicObjectUpdatingForm} from "../johncornish/components/v1/form/BasicObjectUpdatingForm";
import {nullSafeFileName} from "../johncornish/utils/v1/urls";
import {StateValidators} from "../johncornish/components/v1/form/types";
import {Input} from "../johncornish/components/v1/form/Input";
import {SplitScreen} from "../johncornish/components/v1/screen/SplitScreen";
import {TailwindTwoColumnGrid} from "../styled-components/TailwindTwoColumnGrid";
import {CurrentUserPhoto} from "./CurrentUserPhoto";
import {useApi} from "../../store";

function extractPowerformID(powerform_url: string) {
  const match = powerform_url.match(/^.*PowerFormId=([a-zA-Z0-9-]+)&.*$/);
  if (!match) return '';

  const [, derivedPowerformId] = match;
  return derivedPowerformId;
}

export function CreateOrEditAgent({agent}: { agent: Agent | null }) {
  const {tryulaApi} = useApi();

  const [agentState, setAgentState] = useState<AgentCreatingState>(!agent ? InitialCreateAgentState : agent);

  useEffect(() => {
    setAgentState(prevState => ({
      ...prevState,
      powerform_id: extractPowerformID(prevState.powerform_url)
    }));
  }, [agentState.powerform_url]);

  const validateCreateOrEditAgentFields: StateValidators = [
    ['first_name', state => !!state.first_name, 'First name is required'],
    ['last_name', state => !!state.last_name, 'Last name is required'],
    ['email', state => !!state.email, 'Email is required'],
    ['phone_number', validPhoneNumber,
      'Valid phone number is required (only 10 digits, no country code)'],
    ['powerform_url',
      state => !!state.powerform_url,
      'Powerform URL must not be empty'],
    ['powerform_id',
      state => !!state.powerform_id,
      'Powerform ID must not be empty'],
    ['',
      state => !!state.referral_agreement || !!agent?.referral_agreement_url,
      'Referral agreement cannot be empty'],
  ];

  return <SplitScreen screenComponent={TailwindTwoColumnGrid}>
    <div
      className="panelWhite task relative flex justify-between rounded-sm border border-stroke bg-white p-7 shadow-default flex-col gap-5">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col md:flex-row gap-x-8 gap-y-5">
          <BasicObjectUpdatingForm
            resourceName="agent"
            state={agentState}
            setState={setAgentState}
            fieldsWithLabels={CreateOrEditAgentFields}
            useMutator={!agent ? tryulaApi.useCreateAgentMutation : useUpdateAgentMutation}
            validate={validateCreateOrEditAgentFields}
            saveText={!agent ? "Create Agent" : "Save"}
            saveAriaLabel='Submit Create Agent form'
            saveClassName={"ml-1 mt-4 bg-[#27AAE1] hover:bg-blue-500 hover:cursor-pointer text-white font-bold py-2 px-4 rounded block"}
            errorElement={RedError}
            updateSuccessElement={() => <GreenCheckmark label='created successfully'/>}
            rowElement={ProfileFieldRow}
          >
            <p className="font-bold">{nullSafeFileName(agent?.referral_agreement_url)}</p>

            <ProfileFieldRow>
              <>Powerform ID</>
              <Input
                disabled={true}
                forStateField="powerform_id"
                resourceName="agent"
                state={agentState}
                setState={setAgentState}
                className={AgentFieldStyle}/>
            </ProfileFieldRow>
          </BasicObjectUpdatingForm>
        </div>
      </div>
    </div>
    <>{!!agent?.avatar_url && <CurrentUserPhoto
      agent={agent}
      viewer="admin"
    />}</>
  </SplitScreen>;
}

