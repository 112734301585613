import {Agent} from "../lib/object-control/agent";
import {useResendAgentInviteMutation} from "../services/tryulaApi";
import {DefaultPageLayout} from "../lib/layouts/DefaultPageLayout";
import {BlueLinkAnchor} from "../lib/styled-components/BlueLinkAnchor";
import {ReactNode, useState} from "react";
import {RedError} from "../lib/styled-components/RedError";
import {toTestId} from "../lib/utils";
import {APIUserListPage} from "../lib/johncornish/components/v1/list/APIUserListPage";
import {createSubmitHandler} from "../lib/johncornish/components/v1/form/createSubmitHandler";
import {BasicLoadingElement} from "../lib/johncornish/components/v1/form/BasicLoadingElement";
import {UserType} from "../lib/object-control/user";
import {DeactivateWithConfirmation} from "../lib/styled-components/DeactivateWithConfirmation";
import {BlueLinkButton} from "../lib/styled-components/BlueLinkButton";
import {useApi} from "../store";

export function AdminAgentsList({userType}: AdminDashboardProps) {
  const {tryulaApi} = useApi();

  const {
    data: agents,
    error,
    isLoading,
  } = tryulaApi.useGetAllAgentsQuery();

  return <APIUserListPage
    items={agents}
    error={error}
    isLoading={isLoading}
    resourcePlural="agents"
    resourceSingular="agent"
    pageLayoutComponent={DefaultPageLayout}
    itemComponent={userType === 'Admin' ? SuperAdminViewOfAgent : TryulaAdminViewOfAgent}
    emptyResultComponent={() => <p>No agents found.</p>}
  />;
}

const TryulaAdminViewOfAgent = ({agent, children}: { agent: Agent, children?: ReactNode }) => {
  const {tryulaApi} = useApi();

  const [updateError, setUpdateError] = useState('')
  const [
    updateAgent,
    {isLoading: isUpdateAgentLoading}
  ] = tryulaApi.useUpdateAgentMutation();
  const [
    resendAgentInvite,
    {isLoading: isResendAgentInviteLoading}
  ] = useResendAgentInviteMutation();

  const publishOrUnpublishAgent = createSubmitHandler({
    mutator: updateAgent,
    setMutateError: setUpdateError,
    state: {id: agent.id, published: !agent.published}
  });

  const reinstateAgentHandler = createSubmitHandler({
    mutator: updateAgent,
    setMutateError: setUpdateError,
    state: {id: agent.id, opt_out: false}
  });

  const resendAgentInviteHandler = createSubmitHandler({
    mutator: resendAgentInvite,
    setMutateError: setUpdateError,
    state: agent.id
  });

  const agentTestId = toTestId(agent.first_name, agent.last_name);
  return <div data-testid="agent">
    <div className="grid grid-cols-2">
      <div className="col-span-1 grid grid-cols-4 space-x-2">
        <img
          data-testid="avatar-url-receiver"
          className="rounded-full col-span-1 aspect-square w-[56px] h-[56px]"
          src={agent.avatar_url === null ? undefined : agent.avatar_url}
          alt=""
        />

        <div className="col-span-3">
          <p>
            {`${agent.first_name} ${agent.last_name}`}
          </p>

          <p className="ml-4 w-full">
            {agent.brokerage_office_name}
          </p>

          <p className="ml-4 w-full">
            {/* NOTE No test because there's no way to check if an email came through */}
            <BlueLinkButton onClick={resendAgentInviteHandler}>
              Re-send Invite
            </BlueLinkButton>

            <BlueLinkAnchor
              data-testid={`preview-agent-${agentTestId}`}
              href={`/agent/${agent.id}?preview=true`}>
              Preview
            </BlueLinkAnchor>
          </p>
        </div>
      </div>

      <div className="col-span-1 items-end space-x-2">
        <BlueLinkAnchor
          data-testid={`edit-agent-${agentTestId}`}
          href={`/agent/${(agent.id)}`}>
          Edit
        </BlueLinkAnchor>
        {children}
        {agent.opt_out && <button
          className="ml-2 bg-[#A9D046] hover:bg-green-500 text-white font-bold py-2 px-4 rounded"
          data-testid={`agent-reinstate-${agentTestId}`}
          onClick={reinstateAgentHandler}>
          Reinstate
        </button>}
        {agent.published ? <button
          className="ml-2 bg-[#27AAE1] hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
          data-testid={`agent-unpublish-${agentTestId}`}
          onClick={publishOrUnpublishAgent}>
          Unpublish
        </button> : <button
          className="ml-2 bg-[#A9D046] hover:bg-green-500 text-white font-bold py-2 px-4 rounded"
          data-testid={`agent-approve-${agentTestId}`}
          onClick={publishOrUnpublishAgent}>
          Approve
        </button>}
      </div>
    </div>

    {(isUpdateAgentLoading || isResendAgentInviteLoading) && <BasicLoadingElement/>}
    {!!updateError && <RedError>{updateError}</RedError>}
  </div>;
};

const SuperAdminViewOfAgent = ({agent}: { agent: Agent }) => {
  const {tryulaApi} = useApi();

  const [deactivateAgent] = tryulaApi.useDeactivateAgentMutation();
  const [deactivateError, setDeactivateError] = useState('');

  return <TryulaAdminViewOfAgent agent={agent}>
    <DeactivateWithConfirmation
      dataObject={agent}
      uniqueIdentifier={toTestId(agent.first_name, agent.last_name)}
      deactivateMutator={deactivateAgent}
      setDeactivateError={setDeactivateError}/>
    {!!deactivateError && <RedError>{deactivateError}</RedError>}
  </TryulaAdminViewOfAgent>;
};

interface AdminDashboardProps {
  userType: UserType | undefined
}

