import {Agents} from "./Agents";
import {allValuesSelected, withAvailableAndChosen} from "../../lib/johncornish/components/v1/filter/utils";
import {FaLanguage, FaMapMarkerAlt, FaUser} from "react-icons/fa";
import {ReactNode, useCallback, useEffect, useState} from "react";
import {asStringArray} from "../../lib/object-control/language-or-service-area";
import {Input} from "../../lib/johncornish/components/v1/form/Input";
import {getFilterCheckboxes} from "../../lib/johncornish/components/v1/checkbox-selection/getFilterCheckboxes";
import {CheckboxState} from "../../lib/johncornish/components/v1/filter/types";
import {AgentFilter} from "../../lib/object-control/agent";
import {useSearchParams} from "react-router-dom";
import {decodedChosenValuesFromQueryString} from "../../lib/utils";
import './AgentSearch.css';
import {useApi} from "../../store";
import {RedError} from "../../lib/styled-components/RedError";

export const AgentSearch = () => {
  const {tryulaApi} = useApi();

  const [searchParams] = useSearchParams();

  const {
    data: serviceAreas,
    error: serviceAreasError,
    isLoading: areServiceAreasLoading,
  } = tryulaApi.useGetAllServiceAreasQuery();

  const {
    data: languages,
    error: languagesError,
    isLoading: areLanguagesLoading,
  } = tryulaApi.useGetAllLanguagesQuery();

  const [filter, setFilter] =
    useState<AgentFilter>({
      name: searchParams.get('name') || '',
      service_areas: {All: true},
      languages: {All: true}
    })

  useEffect(() => {
    if (!serviceAreas || !languages) {
      return;
    }

    const serviceAreasFromParams = withAvailableAndChosen(
      serviceAreas ?? [],
      decodedChosenValuesFromQueryString(searchParams.get('service_areas')));
    const languagesFromParams = withAvailableAndChosen(
      languages ?? [],
      decodedChosenValuesFromQueryString(searchParams.get('languages')));

    setFilter(prevState => ({
      ...prevState,
      service_areas: !!searchParams.get('service_areas') ? serviceAreasFromParams : allValuesSelected(asStringArray(serviceAreas ?? [])),
      languages: !!searchParams.get('languages') ? languagesFromParams : allValuesSelected(asStringArray(languages ?? []))
    }));
  }, [languages, searchParams, serviceAreas]);

  const [open, setOpen] =
    useState({
      service_areas: false,
      languages: false
    })

  const closePopupsOnEscape = useCallback((event: { key: string }) => {
    if (event.key === "Escape") {
      setOpen({
        languages: false,
        service_areas: false
      });
    }
  }, [setOpen]);
  const closePopupsOnClick = useCallback(() => {
    setOpen({
      languages: false,
      service_areas: false
    });
  }, [setOpen]);

  useEffect(() => {
    document.addEventListener("keydown", closePopupsOnEscape, false);
    document.addEventListener("click", closePopupsOnClick, false);

    return () => {
      document.removeEventListener("keydown", closePopupsOnEscape, false);
      document.removeEventListener("click", closePopupsOnClick, false);
    };
  }, [closePopupsOnClick, closePopupsOnEscape]);

  return <section className='agent-search'>
    <div
      className="flex flex-col gap-2 mx-auto items-end">
      <div
        className="max-w-full lg:w-[750px] rounded-[8px] sm:rounded-[1020px] shadow-[0_3px_15px_0px_rgba(0,0,0,0.16)] mx-auto relative z-10">
        <div className="flex flex-col sm:flex-row sm:items-center pl-[16px] sm:pl-[42px] sm:pr-[42px] py-[15px] gap-4">
          <FilterText>
            <FaUser className="text-[18px] text-[#1f4883]"/>
            <Input forStateField={"name"}
                   state={filter}
                   placeholder="Agent name"
                   setState={setFilter}
                   className="font-normal text-md focus:outline-none border-none inline-block px-0"/>
          </FilterText>

          <VerticalBar/>

          <FilterButton>
            <FaMapMarkerAlt className="text-[18px] text-[#1f4883]"/>
            <button data-testid={`open-location-filter`}
                    onClick={event => {
                      event.stopPropagation();
                      setOpen(prevState =>
                        ({
                          languages: false,
                          service_areas: !prevState.service_areas
                        }));
                    }}>
              Location
            </button>
          </FilterButton>

          <VerticalBar/>

          <FilterButton>
            <FaLanguage className="text-[18px] text-[#1f4883]"/>
            <button data-testid={`open-languages-filter`}
                    onClick={event => {
                      event.stopPropagation();
                      setOpen(prevState =>
                        ({
                          languages: !prevState.languages,
                          service_areas: false
                        }));
                    }}>
              Language
            </button>
          </FilterButton>
        </div>

        <FloatingCheckboxSelection
          isLoading={areServiceAreasLoading}
          error={serviceAreasError}
          isVisible={open.service_areas}
          selectionName="locations">
          {getFilterCheckboxes(
            "locations",
            filter.service_areas as CheckboxState,
            (selectedValues: CheckboxState) =>
              setFilter(prevState =>
                ({
                  ...prevState,
                  service_areas: selectedValues
                })))}
        </FloatingCheckboxSelection>

        <FloatingCheckboxSelection
          isLoading={areLanguagesLoading}
          error={languagesError}
          isVisible={open.languages}
          selectionName="languages">
          {getFilterCheckboxes(
            "languages",
            filter.languages as CheckboxState,
            (selectedValues: CheckboxState) =>
              setFilter(prevState =>
                ({
                  ...prevState,
                  languages: selectedValues
                })))}
        </FloatingCheckboxSelection>
      </div>
    </div>

    <strong className='explanation'>
      All real estate agents have been personally invited based upon their proven merit and ability to cater to the
      modern home buyer and seller.
    </strong>

    <strong className='explanation'>
      No agent can simply pay to be listed on our platform.
      The $1000 rebate is a bonus to the earnings and savings our agents can provide.
    </strong>

    <section className="pb-2">
      <Agents filter={filter}/>
    </section>
  </section>;
};

interface FloatingCheckboxSelectionProps {
  children: ReactNode[],
  isVisible?: boolean,
  selectionName: string,
  isLoading: boolean,
  error: any
}

function FloatingCheckboxSelection(
  {children, isVisible, selectionName, isLoading, error}: FloatingCheckboxSelectionProps
) {
  if (!isVisible) {
    return null;
  }

  if (isLoading) {
    return <div aria-label={`loading ${selectionName}`}>
      <div
        onClick={event => event.stopPropagation()}
        className="space-y-4 rounded-xl shadow-lg bg-white p-8 sm:px-16 absolute z-20 right-0 w-full lg:mx-[15px] border border-cyan-600/50">
        <label>
          <input
            className="me-1"
            type="checkbox"
          />
          All
        </label>
        <div className="flex justify-center">
          <div className="animate-pulse rounded-lg bg-gray-200 h-[46px] w-full"></div>
        </div>
      </div>
    </div>;
  }

  if (error) {
    return <div aria-label={'loading service areas'}>
      <div
        onClick={event => event.stopPropagation()}
        className="space-y-4 rounded-xl shadow-lg bg-white p-8 sm:px-16 absolute z-20 right-0 w-full lg:mx-[15px] border border-cyan-600/50">
        <label>
          <input
            className="me-1"
            type="checkbox"
          />
          All
        </label>
        <div className="flex justify-center">
          <RedError>Error loading {selectionName}</RedError>
        </div>
      </div>
    </div>;
  }

  const [allCheckbox, ...otherCheckboxes] = children;

  return <div data-testid={`${selectionName}-selection`}>
    <div
      onClick={event => event.stopPropagation()}
      className="space-y-4 rounded-xl shadow-lg bg-white p-8 sm:px-16 absolute z-20 right-0 w-full lg:mx-[15px] border border-cyan-600/50">
      {allCheckbox}
      <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 gap-x-16">
        {otherCheckboxes}
      </div>
    </div>
  </div>;
}

function FilterButton({children}: { children: any }) {
  return <div className="form-input flex flex-row items-center sm:py-[12px] py-[6px] sm:px-[10px] gap-4 flex-1">
    {children}
  </div>;
}

function VerticalBar() {
  return <span className="vertical-line border-r-[1px] border-black/25 w-[1px] h-[46px] hidden sm:block"></span>;
}

function FilterText({children}: { children: any }) {
  return <div className="form-input flex flex-row items-center flex-2 gap-4">
    {children}
  </div>;
}
