import {RedError} from "../lib/styled-components/RedError";
import {AnyAdminStats} from "../lib/object-control/stats";
import {useCurrentUser} from "../features/auth/useUserData";
import {LoginFailuresForSuperAdmin} from "./LoginFailuresForSuperAdmin";
import {useApi} from "../store";
import {Link} from "react-router-dom";

export function AdminStats() {
  const {user} = useCurrentUser();

  const {tryulaApi} = useApi();

  const {
    data: anyAdminStats,
    error: anyAdminStatsError,
    isLoading: isAnyAdminStatsLoading
  } = tryulaApi.useGetAnyAdminStatsQuery();

  if (isAnyAdminStatsLoading || !anyAdminStats) {
    return <p>Loading admin stats bundle 1...</p>
  }

  if (!!anyAdminStatsError) {
    return <RedError>Unknown error fetching admin stats bundle 1</RedError>;
  }

  return <>
    <div className="grid grid-cols-3 gap-4">
      <Link to="/transactions?transaction_status=active"
         data-testid="active-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-green-400 rounded-lg shadow hover:bg-green-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          Active
        </h5>

        <p
          data-testid="num-active-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).all_time_active}
        </p>
      </Link>

      <Link to="/transactions?transaction_status=pending"
         data-testid="pending-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-yellow-400 rounded-lg shadow hover:bg-yellow-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          Pending
        </h5>

        <p
          data-testid="num-pending-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).all_time_pending}
        </p>
      </Link>

      <Link to="/transactions?transaction_status=closed"
         data-testid="closed-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-red-400 rounded-lg shadow hover:bg-red-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          Closed
        </h5>

        <p
          data-testid="num-closed-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).all_time_closed}
        </p>
      </Link>

      {/*>45 days*/}
      <Link to="/transactions?pending_date=%3E45%20days&transaction_status=pending"
         data-testid="gt45d-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-red-400 rounded-lg shadow hover:bg-red-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          {">"} 45 days
        </h5>

        <p
          data-testid="num-gt45d-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).gt45d_pending}
        </p>
      </Link>

      {user?.user_type === 'Admin' && <LoginFailuresForSuperAdmin/>}
    </div>
  </>;
}