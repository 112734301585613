import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "react";
import {SignUpStateFields} from "../lib/johncornish/auth/v1/types";
import {useSignUpMutation} from "../services/authApi";
import {setCredentials} from "../features/auth/userDataSlice";
import {loginHref} from "../lib/utils";
import {RedError} from "../lib/styled-components/RedError";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import ReCAPTCHA from 'react-google-recaptcha';
import {ReCAPTCHASiteKey} from "../services/constants";
import {StateValidators} from "../lib/johncornish/components/v1/form/types";
import {createFormSubmitHandler} from "../lib/johncornish/components/v1/form/createFormSubmitHandler";
import {Input, Password} from "../lib/johncornish/components/v1/form/Input";
import {BasicLoadingElement} from "../lib/johncornish/components/v1/form/BasicLoadingElement";
import {StandardInputClassName} from "../lib/styles";
import {DocuSignExplanationPopUp} from "../lib/styled-components/DocuSignExplanationPopUp";
import {ContentHome} from "../config/siteSettings";
import {BlueLinkAnchor} from "../lib/styled-components/BlueLinkAnchor";
import './SignUpPage.css';


export function SignUpPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const returnUrl = searchParams.get('return_url');

  const [signUpFields, setSignUpFields] = useState<SignUpStateFields>({
    email: '',
    password: '',
    confirm_password: '',
    privacy_policy_consent: false
  })
  const [signUpError, setSignUpError] = useState('');
  const [validReCAPTCHA, setValidReCAPTCHA] = useState(false);

  const [
    signUp,
    {isLoading}
  ] = useSignUpMutation();

  const onSignUpSuccess = () => {
    dispatch(setCredentials({user: null, token: null}));
    navigate(loginHref(returnUrl, true, signUpFields.email));
  }

  const conditionalValidateReCaptcha: StateValidators =
    !!ReCAPTCHASiteKey ? [
      ['', () => validReCAPTCHA, 'You must complete the CAPTCHA prompt']
    ] : [];

  const onSignUpSubmit = createFormSubmitHandler({
    setMutateError: setSignUpError,
    mutator: signUp,
    state: signUpFields,
    onSuccess: onSignUpSuccess,
    wrapState: state => ({user: state}),
    validate: [
      ['confirm_password',
        (state: SignUpStateFields) => state.password === state.confirm_password,
        'Passwords must match'],
      ['privacy_policy_consent',
        (state: SignUpStateFields) => state.privacy_policy_consent,
        'You must agree to the privacy policy'],
      ...conditionalValidateReCaptcha
    ]
  })

  return <>
    <PageTitle title="Sign Up for Tryula.com"/>
    <div className="flex flex-col items-center">
      <div className="grid grid-cols-2 items-center gap-x-4 mb-6">
        <a
          data-testid="login-tab"
          href={loginHref(returnUrl)}
          className="col-span-1 bg-transparent border border-[#27AAE1] rounded py-2 px-6 text-center hover:cursor-pointer">
          Login
        </a>
        <button
          data-testid="sign-up-tab"
          disabled={true}
          className="col-span-1 bg-[#27AAE1] border border-[#27AAE1] rounded py-2 px-6 text-center text-white hover:cursor-pointer">
          Sign Up
        </button>
      </div>

      <DocuSignExplanationPopUp/>

      <form className="sign-up-form flex flex-col items-center gap-y-2" onSubmit={onSignUpSubmit}>
        <fieldset className="text-fields">
          <section>
            <label htmlFor="emailAddress">Email Address</label>
            <Input id="emailAddress"
                   autoFocus={true}
                   className={StandardInputClassName}
                   forStateField={"email"}
                   state={signUpFields}
                   setState={setSignUpFields}
                   required={true}/>
          </section>

          <section>
            <label htmlFor="password">Password</label>
            <Password id="password"
                      className={StandardInputClassName}
                      forStateField={"password"}
                      state={signUpFields}
                      setState={setSignUpFields}
                      required={true}/>
          </section>

          <section>
            <label htmlFor="confirmPassword">Confirm Password</label>
            <Password id="confirmPassword"
                      className={StandardInputClassName}
                      forStateField={"confirm_password"}
                      state={signUpFields}
                      setState={setSignUpFields}
                      required={true}/>
          </section>
        </fieldset>

        <label className="gap-x-2 block text-center">
          <input
            type="checkbox"
            data-testid="agree-or-disagree"
            className="m-2 hover:cursor-pointer disabled:hover:cursor-not-allowed"
            onClick={() =>
              setSignUpFields(
                (prevState: SignUpStateFields) => ({
                  ...prevState,
                  privacy_policy_consent: !prevState.privacy_policy_consent
                }))}
          />
          I agree to the
          <BlueLinkAnchor target={'_blank'} to={`${ContentHome}/privacy-policy-and-terms-of-use/`}>Privacy
            Policy</BlueLinkAnchor>.
        </label>

        {!!signUpError && <RedError>{signUpError}</RedError>}

        {!!ReCAPTCHASiteKey && <ReCAPTCHA
          sitekey={ReCAPTCHASiteKey}
          onChange={() => setValidReCAPTCHA(true)}
        />}

        <input className="bg-[#a9d046] text-white w-64 p-4 py-4 text-center rounded-[100px] font-bold uppercase"
               type="submit"
               value="Sign Up"
               data-testid="submit-sign-up"/>
        {isLoading && <BasicLoadingElement/>}
      </form>
    </div>
  </>;
}