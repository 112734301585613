import {DefaultPageLayout} from "../lib/layouts/DefaultPageLayout";
import {createContext, FC, useState} from "react";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {APIUserListPage} from "../lib/johncornish/components/v1/list/APIUserListPage";
import {getTransactions, pendingSinceDecodedOrDefault} from "./transactions-page-utils";
import {useCurrentUser} from "../features/auth/useUserData";
import {useSearchParams} from "react-router-dom";

import './TransactionsPage.css'
import {TransactionFilter, TransactionSearchBox} from "./TransactionSearchBox";
import {EmptyFunc} from "../lib/johncornish/utils/v1/func";
import {Transaction} from "../lib/transactions/Transaction";
import {useApi} from "../store";
import {useAllow} from "../lib/auth/useAllow";

interface TransactionsPageProps {
  view?: TransactionPageView
}

export const TransactionPageContext =
  createContext<{
    view: TransactionPageView | undefined,
    refetch: () => void
  }>({
    view: undefined,
    refetch: EmptyFunc
  });

export const TransactionsPage: FC<TransactionsPageProps> = ({view}) => {
  const {user} = useCurrentUser();
  useAllow(user, 'Agent', 'Admin', 'TryulaAdmin');

  const [searchParams] = useSearchParams();

  const {tryulaApi} = useApi();

  const urlFilteredTransactionStatus: string | null = searchParams.get("transaction_status");

  const pendingSinceDefault = pendingSinceDecodedOrDefault(searchParams.get("pending_date"));
  const initialFilter = {
    transaction_type: '',
    transaction_status: urlFilteredTransactionStatus || '',
    pending_date: pendingSinceDefault,
    customer_name_or_customer_email: '',
    address: ''
  };
  const [filter, setFilter] = useState<TransactionFilter>(initialFilter);

  const {
    refetch,
    data: transactions,
    error,
    isLoading,
  } = tryulaApi.useGetAllTransactionsQuery(view === 'archived');

  const agentNoTransactions = view === 'archived'
    ? AgentNoTransactionsOnArchive
    : AgentNoTransactions;

  const noTransactionsComponent = user?.user_type === 'Agent'
    ? agentNoTransactions
    : AdminNoTransactions;

  const handleDropdownFilterChange = (forStateField: string) =>
    (event: { target: { value: string; }; }) => {
      setFilter((prevState: typeof filter) => ({
        ...prevState,
        [forStateField]: event.target.value
      }))
    };

  const handleFilterType = handleDropdownFilterChange('transaction_type');
  const handleFilterStatus = handleDropdownFilterChange('transaction_status');
  const handleFilterPendingFor = handleDropdownFilterChange('pending_date');

  const adminDateFilter = user?.user_type === 'Agent'
    ? null
    : <div className="mt-3">
      <label className="text-[#000]">
        Pending for...
        <select data-testid="select-pending-for"
                className="p-2 w-full bg-white border outline-neutral-700"
                onChange={handleFilterPendingFor}
                defaultValue={pendingSinceDefault}>
          <option data-testid="filter-pending-for-option-All" value="All">
            All
          </option>
          <option data-testid="filter-pending-for-option-gt30d" value=">30 days">
            {'>30 days'}
          </option>
          <option data-testid="filter-pending-for-option-gt45d" value=">45 days">
            {'>45 days'}
          </option>
          <option data-testid="filter-pending-for-option-gt60d" value=">60 days">
            {'>60 days'}
          </option>
          <option data-testid="filter-pending-for-option-gt90d" value=">90 days">
            {'>90 days'}
          </option>
        </select>
      </label>
    </div>;

  return <>
    <PageTitle title={view === 'archived' ? 'Archived Transactions' : 'Transactions'}/>
    <TransactionSearchBox
      handleFilterType={handleFilterType}
      filter={filter}
      setFilter={setFilter}
      initialFilter={initialFilter}
      adminDateFilter={adminDateFilter}
      view={view}
      handleFilterStatus={handleFilterStatus}
      urlFilteredTransactionStatus={urlFilteredTransactionStatus}
      user={user}
    />
    <TransactionPageContext.Provider value={{view, refetch}}>
      <APIUserListPage
        items={getTransactions(transactions, user, view)}
        error={error}
        isLoading={isLoading}
        resourcePlural="transactions"
        resourceSingular="transaction"
        pageLayoutComponent={DefaultPageLayout}
        itemComponent={Transaction}
        emptyResultComponent={noTransactionsComponent}
        filter={filter}
      />
    </TransactionPageContext.Provider>
  </>;
}

export type TransactionPageView = 'closed' | 'archived';

const AdminNoTransactions = () =>
  <p>
    No transactions found.
  </p>;

const AgentNoTransactions = () =>
  <p>
    No transactions yet.
    Just sit back, relax, and wait for us to do work for you.
  </p>;

const AgentNoTransactionsOnArchive = () =>
  <p>
    No transactions archived.
  </p>;
