import React, {useState, ChangeEvent} from 'react';

interface PrivacyPopupProps {
  onAcknowledge?: () => void;
  initialShow?: boolean;
}

export const DocuSignExplanationPopUp: React.FC<PrivacyPopupProps> =
  ({
     onAcknowledge,
     initialShow = true
   }) => {
    const [show, setShow] = useState<boolean>(initialShow);

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
      if (e.target.checked) {
        setShow(false);
        onAcknowledge?.();
      }
    };

    if (!show) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full">
          <div className="space-y-4 mb-6">
            <p className="text-center font-bold">
              By signing the guarantee, the contact information becomes viewable.
            </p>
            <p className="text-center font-bold">
              We encourage you to interview multiple agents and choose the one that fits you.
            </p>
          </div>

          <div className="flex justify-center">
            <label className="flex items-center gap-2 cursor-pointer">
              <span>Proceed</span>
              <input
                type="checkbox"
                data-testid="proceed-to-signup"
                onChange={handleCheckboxChange}
                className="w-4 h-4"
                style={{accentColor: '#A9D046'}}
              />
            </label>
          </div>
        </div>
      </div>
    );
  };
