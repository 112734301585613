import {FC} from "react";
import {BlueLinkAnchor} from "../styled-components/BlueLinkAnchor";
import {signUpHref} from "../utils";
import {EngageModal} from "./EngageModal";
import {User} from "../object-control/user";
import {PendingEngagement} from "../object-control/pending-engagement";
import {nullSafeFullURL} from "../johncornish/utils/v1/urls";
import {Agent} from "../object-control/agent";
import {useLocation} from "react-router-dom";

export type ViewCustomerAgentProfileProps = {
  agent: Agent,
  user: User | null,
  onClickEngageAgent: () => void,
  unavailableAgentInformation: boolean,
  showEngageModal: boolean,
  setShowEngageModal: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  validNumProperties: boolean,
  handleSubmitEngagement: (submitterValue?: any) => Promise<void>,
  isPendingEngagementLoading: any,
  pendingEngagementError: string,
  pendingEngagement: PendingEngagement,
  setPendingEngagement: (value: (((prevState: PendingEngagement) => PendingEngagement) | PendingEngagement)) => void,
  isPendingEngagementSuccess: boolean
};

export const ViewCustomerAgentProfile: FC<ViewCustomerAgentProfileProps> = (props) => {
  const location = useLocation();

  return <div
    className="max-w-full mx-auto pb-[30px] flex flex-col lg:grid lg:grid-cols-9 items-center lg:items-start lg:flex-row">
    <div className="lg:col-span-3">
      <div
        className="shadow-card w-full max-w-[408px] px-12 lg:p-[40px] lg:py-[80px] rounded-[15px] flex flex-wrap flex-col justify-center gap-[20px] mb-[40px]">
        {!!props.agent.avatar_url && <img
          className="rounded-full mx-auto w-[120px] h-[120px] sm:w-[168px] sm:h-[168px] aspect-square object-cover mb-1"
          src={props.agent.avatar_url}
          alt=""/>}

        <div data-testid="profile-agent-name" className="agentName text-center uppercase">
          {`${props.agent.first_name} ${props.agent.last_name}`}
        </div>

        {(props.user?.user_type === "Admin" || props.user?.user_type === "TryulaAdmin")
          && <div data-testid="profile-referral-agreement" className="text-center">
            <a
              target="_blank"
              rel="noreferrer"
              className="text-[#27AAE1] hover:underline"
              data-testid="agent-referral-agreement-link"
              href={nullSafeFullURL(props.agent.referral_agreement_url, '#')}>
              Referral Agreement
            </a>
          </div>}

        {props.user?.user_type === "Customer"
          ? <button
            data-testid="profile-engage-agent"
            className="agent-profile__engage-action bg-[#A9D046] uppercase text-white rounded-[100px] p-[18px] text-center"
            onClick={props.onClickEngageAgent}
          >
            Contact Agent
          </button>
          : <BlueLinkAnchor href={signUpHref(location.pathname, "engage=true")}
                            className="agent-profile__engage-action bg-[#A9D046] uppercase text-white rounded-[100px] p-[18px] text-center"
                            data-testid="profile-engage-agent">
            Contact Agent
          </BlueLinkAnchor>}
      </div>
    </div>

    <div className="lg:col-span-6 lg:grid lg:grid-cols-5 w-4/5 sm:w-3/5 lg:w-full">
      <div className="lg:col-span-2 col-span-5">
        <div className="flex flex-wrap gap-y-[10px] gap-x-[40px] mb-5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 text-[16px] gap-4 mb-3">
            <div className="col-span-1 space-y-4">
              <div className="flex flex-col gap-1">
                <span className="font-bold">Agent License Number:</span>
                <span>{props.agent.agent_license}</span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-bold">Brokerage Office Name:</span>
                <span>{props.agent.brokerage_office_name}</span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-bold">Brokerage License Number:</span>
                <span>{props.agent.brokerage_license}</span>
              </div>
            </div>

            <div className="col-span-1 space-y-4">
              <div className="flex flex-col gap-1">
                <span className="font-bold">Email:</span>
                <span data-testid="agent-email" className="break-words">
                    {props.agent.email}
                  </span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-bold">Phone:</span>
                <span data-testid="agent-phone-number">{props.agent.phone_number}</span>
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-bold">Languages Spoken:</span>
                <span>{props.agent.languages.join(", ")}</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="col-span-5 lg:col-span-3">
        <div className="flex flex-wrap gap-y-[10px] gap-x-[40px] mb-5">
          <div className="flex flex-col gap-1 mb-5 mx-auto">
            <span className="font-bold text-start">Service Areas:</span>
            <span className="grid grid-cols-2 gap-x-4 text-sm">
              {props.agent.service_areas.map((s: string) => <span className="block col-span-1 px-1">{s}</span>)}
            </span>
          </div>
        </div>
      </div>

      <div className="lg:col-span-5 col-span-3">
        {!!props.agent.bio && <div className="flex flex-col gap-1 mb-5">
          <span className="font-bold">Bio:</span>
          <p className="whitespace-break-spaces">{props.agent.bio}</p>
        </div>}
      </div>
    </div>

    <EngageModal
      show={props.showEngageModal}
      setShow={props.setShowEngageModal}
      user={props.user}
      validNumProperties={props.validNumProperties}
      handleSubmitEngagement={props.handleSubmitEngagement}
      agent={props.agent}
      isPendingEngagementLoading={props.isPendingEngagementLoading}
      pendingEngagementError={props.pendingEngagementError}
      pendingEngagement={props.pendingEngagement}
      setPendingEngagement={props.setPendingEngagement}
      isPendingEngagementSuccess={props.isPendingEngagementSuccess}
    />
  </div>;
}