import {User} from "../object-control/user";
import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {loginHref} from "../utils";

export const useAllow =
  (currentUser: User | null, ...allowedUserTypes: string[]) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!currentUser || !allowedUserTypes.includes(currentUser.user_type)) {
        navigate(loginHref(location.pathname));
      }
    }, [allowedUserTypes, currentUser, location.pathname, navigate]);
  }